import { Component, Prop, Vue } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfStore from "@/vue/domain/store/df-store";
import DfPromotion from "@/vue/domain/promotion/df-promotion";

@Component
export default class extends Vue {
  @Prop({ type: String, default: "" })
  pdfUrl!: string | null;

  @Prop({ type: Boolean, default: false })
  fullscreen: boolean;

  @Prop({ type: String, default: "" })
  position!: string;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get promotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }

  get pdfjsUrl(): string {
    if (this.currentStore?.alias && this.promotion?.alias) {
      const viewOptions = this.position === 'BANNERS' ? '&PREVIEW_MODE_ENABLED=true&PAGE_GROUPING=NONE' : '';
      const viewerPdfOptions = `${Utils.getViewerPdfOptions(this.position)}${viewOptions}`;
      return `${Utils.getViewerPdfUrl()}/punti-vendita/${this.currentStore.alias}/promozioni/${this.promotion.alias}?${viewerPdfOptions}`;
    } else {
      return "";
    }
  }

  get mobileView(): boolean {
    const clientWidth: number = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return clientWidth <= 544;
  }

  private onLoad(event: any) {
    //console.log(event);
  }
}
